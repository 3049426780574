.pro-sidebar {
    color: #002C3F;
    height: 100%;
    width: 270px;
    min-width: 270px;
    text-align: left;
    transition: width, left, right, 0.3s;
    position: fixed;
    background-color: white ;
    border-right: 3px solid #f4f7f7;
    /* margin-top: 6em; */
}
.pro-sidebar-inner {
    background-color: white !important;
    height: 100%;
    position: relative;
    z-index: 101;
}
.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item {
    position: relative;
    background-color: white;
}
.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-header {
    border-bottom: none;
    text-align: center;
    padding:  3em;
}
.pro-sidebar .pro-menu.shaped .pro-menu-item > .pro-inner-item > .pro-icon-wrapper {
    background-color: white;
}
.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-footer {
    border-bottom: none;
    text-align: center;
    padding:  2em;
}
.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-footer{
    border-top: none;
}
#input__style{
   
    height: 35px;
    font-family: sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.17);
    box-sizing: border-box;
    border-radius: 10px;
}
#text__area2{
    
    min-height: 10em;
    font-family: sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.17);
    box-sizing: border-box;
    border-radius: 10px;
}
#label__style{
    font-family: sans-serif;
font-style: normal;
font-weight: 300;
font-size: 16px;
line-height: 14px;
}
.btn__help {
    background: #00a359 !important;
    color: white !important;
    border: none !important;
    padding: 10px 20px !important;
    border-radius: 15px !important;
font-style: normal !important;
font-weight: 500 !important;
font-size: 16px !important;
    font-family:   sans-serif !important; 
}
.btn__help:hover {
    /* background-color: #03c56e  !important; */
    box-shadow:0 5px 20px rgba(0, 35, 49, 0.2) !important ;
    color: white;
  }
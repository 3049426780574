.body__span{
    font-family: sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    font-weight: 600;
    color: #000000;}
.title{
    font-family: sans-serif;
font-style: normal;
font-weight: normal;
font-size: 16px;
font-weight: 600;
color: #00a359;
}
.footer_container{
    background-color: #fff;
    display: flex;
    flex-direction: column;
    
}
.footer_content{
display: flex;
flex-direction: row;
/* padding: 1em 0 1em 10em; */
}
.bottom_line{
display: flex;
flex-direction: row;

}
.col_content{
    display: flex;
    flex-direction: column;
    text-align: center;
}
.btn_footer{
border-radius: 5px;
font-family: sans-serif;
font-style: normal;
font-weight: 600;
font-size: 12px !important;
color: #fff;
background-color: #00a359 !important;
margin-bottom: 1em;
height: 30px;
margin: 0 auto 1em auto;
width: 45%;
}
.btn_footer2{
border-radius: 5px;
font-family: sans-serif;
font-style: normal;
font-weight: 600;
font-size: 12px !important;
color: #fff;
background-color: #00a359 !important;
margin-bottom: 1em;
height: 30px;
margin: 4em auto 1em auto;
width: 45%;
}
.footer_font{
    font-family: sans-serif;
font-style: normal;
font-weight: normal;
font-size: 12px !important;

text-align: center;

color: rgba(0, 0, 0, 0.46);
}
.contact{
    font-family: sans-serif;
    font-style: normal;
font-weight: 600;
font-size: 12px;
text-decoration: underline;
/* identical to box height */



color: #00A359;
}
.landing__ctn {
  width: 100%;
  /* margin-top: 6em; */
  margin-left: -4em;
  position: absolute;
  top: 20px;
  background-color: white;
  border-radius: 15px;
}
.MuiIconButton-root {
  padding: 0 !important;
}
.question_dyn {
  width: 100%;
  position: absolute;
  top: 0px;
  z-index: 100;
}
.landing__client_tester {
  width: 100%;
  /* margin-top: 6em; */
  position: absolute;
  top: 20px;
  background-color: white;
  border-radius: 15px;
}
.btn_filter2{
    border-radius: 20px !important;
    min-width: 94px;
    height: 31.2px;
border: none !important;
margin-right: 2em;
font-family: sans-serif !important;
font-style: normal;
font-weight: 500 !important;
font-size: 13px !important;
line-height: 15px;
color: #024089 !important;
/* identical to box height */
background: rgba(204, 204, 204, 0.11) !important;
text-align: center !important;
}
.btn_filter2_selected2{
    border-radius: 20px !important;
    min-width: 94px;
border: none !important;
height: 31.2px;
margin-right: 2em;
font-family: sans-serif !important;
font-style: normal;
font-weight: 500 !important;
font-size: 13px !important;
line-height: 15px;
color: #fff !important;
/* identical to box height */
background: #024089 !important;
text-align: center !important;
}
.btn_filter3{
    border-radius: 20px !important;
    min-width: 94px;
    height: 31.2px;
border: none !important;
margin-right: 2em;
font-family: sans-serif !important;
font-style: normal;
font-weight: 500 !important;
font-size: 13px !important;
line-height: 15px;
color: #F68E12 !important;
/* identical to box height */
background: rgba(204, 204, 204, 0.11) !important;
text-align: center !important;
}
.btn_filter3_selected3{
    border-radius: 20px !important;
    min-width: 94px;
border: none !important;
height: 31.2px;
margin-right: 2em;
font-family: sans-serif !important;
font-style: normal;
font-weight: 500 !important;
font-size: 13px !important;
line-height: 15px;
color: #fff !important;
/* identical to box height */
background: #F68E12 !important;
text-align: center !important;
}
.btn_filter4{
    border-radius: 20px !important;
    min-width: 94px;
    height: 31.2px;
border: none !important;
margin-right: 2em;
font-family: sans-serif !important;
font-style: normal;
font-weight: 500 !important;
font-size: 13px !important;
line-height: 15px;
color: #FB4141 !important;
/* identical to box height */
background: rgba(204, 204, 204, 0.11) !important;
text-align: center !important;
}
.btn_filter4_selected4{
    border-radius: 20px !important;
    min-width: 94px;
border: none !important;
height: 31.2px;
margin-right: 2em;
font-family: sans-serif !important;
font-style: normal;
font-weight: 500 !important;
font-size: 13px !important;
line-height: 15px;
color: #fff !important;
/* identical to box height */
background: #FB4141 !important;
text-align: center !important;
}
.btn_filter1{
    border-radius: 20px !important;
    min-width: 94px;
    height: 31.2px;
border: none !important;
margin-right: 2em;
font-family: sans-serif !important;
font-style: normal;
font-weight: 500 !important;
font-size: 13px !important;
line-height: 15px;
color: #17A25C !important;
/* identical to box height */
background: rgba(204, 204, 204, 0.11) !important;
text-align: center !important;
}
.btn_filter1_selected1{
    border-radius: 20px !important;
    min-width: 94px;
border: none !important;
height: 31.2px;
margin-right: 2em;
font-family: sans-serif !important;
font-style: normal;
font-weight: 500 !important;
font-size: 13px !important;
line-height: 15px;
color: #fff !important;
/* identical to box height */
background: #17A25C !important;
text-align: center !important;
}
.row_3{
    padding: 1em 0em 1em 3em;
}
.img_style3{
    cursor: pointer;
    margin: 0 1em 0 auto;
    width: 14px;
}
.list__items {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}
.btn__1 {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 16px !important;
  background-color: #00a359 !important;
  border-color: #00a359 !important;
  margin-right: 1rem;
  width: 238px;
}
.btn__2 {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 16px !important;
  background-color: rgba(68, 61, 246, 0.1) !important;
  border-color: rgba(68, 61, 246, 0.1) !important;
  width: 238px;
  color: #969696 !important;
}
.list__items2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 37em;
}
.pics {
  display: flex;
}
.home__ctn {
  margin-top: 8em;
  width: 98%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 15px;
}

.container_home {
  margin: 1em 0;
  padding: 1em 3em;
}
.title__1 {
  font-size: 50px;
  font-weight: 600;
  color: #002c3f;
  font-family: sans-serif;
  text-align: start;
}
.title__8 {
  font-size: 50px;
  font-weight: 600;
  color: #002c3f;
  font-family: sans-serif;
  text-align: center;
}
.title__6 {
  font-size: 42px;
  font-weight: 600;
  color: #002c3f;
  font-family: sans-serif;
  text-align: start;
}
.title__2 {
  color: #002c3f;
  font-size: 28px;
  font-weight: 300;
  font-family: sans-serif;
  text-align: start;
  max-width: 34.5em;
}
.title__7 {
  color: #002c3f;
  font-size: 24px;
  font-weight: 300;
  font-family: sans-serif;
  text-align: start;
  max-width: 34.5em;
}
.title__4 {
  color: #002c3f;
  font-size: 1.5em;
  font-family: sans-serif;
  font-weight: 600;
}
.title__3 {
  color: #002c3f;
  font-size: 2em;
  font-weight: 600;
  font-family: sans-serif;
}
.title__5 {
  font-size: 1.1em;
  color: #002c3f;
  font-weight: 600;
  font-family: sans-serif;
}
.title__card {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;

  text-align: start;
  color: #002c3f;
}
.text__card {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  color: #00a359;
}
.title__card2 {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  margin-bottom: 0.5em;
  text-align: start;
  color: #002c3f;
}
.text__card2 {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  text-align: start;
  color: #002c3f;
}
.row__equation {
  padding: 1em 2em;
  margin-bottom: 2em;
}
.row__stat {
  padding: 2em 3em;
  display: flex;
}
.row__stat2 {
  background: rgba(204, 204, 204, 0.3);
  border-radius: 20px;
  padding: 2em 3em;
  display: flex;
}
.equation__legend {
  font-size: 1.1em;
  color: #002c3f;
  width: 40em;
  text-align: initial;
  font-family: sans-serif;
}

.list1 {
  font-size: 1.3em;
  font-family: sans-serif;
  text-align: initial;
  color: #002c3f;
}
.list2 {
  font-size: 1.3em;
  font-family: sans-serif;
  text-align: initial;
  width: 20em;
  color: #002c3f;
}
.subtitle {
  font-size: 1.5em;
  font-family: sans-serif;
  text-align: initial;
  font-weight: 600;
  margin: 1em 0;
  color: #002c3f;
}
.counters {
  display: flex;
  flex-direction: column;
  padding: 0.5em 8em;
  background-color: #fff;
  counter-reset: counter-one;
  text-align: left;
}
.counters2 {
  display: flex;
  flex-direction: column;
  padding: 0.5em 8em;
  background-color: #fff;
  text-align: left;
  counter-reset: counter-two;
}
.list1::before {
  counter-increment: counter-one;
  content: counter(counter-one) "|";
  font-size: 1.8em;
  color: #00a359;
}
.list2::before {
  counter-increment: counter-two;
  content: counter(counter-two) "|";
  font-size: 1.8em;
  color: #00a359;
}
.video-react .video-react-video {
  box-shadow: 0 5px 20px rgba(0, 44, 63, 0.2);
}
.btn_home {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */
  height: 50px;
  display: flex;
  align-items: center;
  text-align: center;

  /* Primary / CDM White */
  background-color: #00a359 !important ;
  color: #ffffff;
}
.video-react-big-play-button-left {
  font-size: 3em;
  line-height: 1.5em;
  height: 1.5em;
  width: 3em;
  display: block;
  position: absolute;
  margin: 4.7em 7.5em;
  padding: 0 !;
  cursor: pointer;
  opacity: 1;
  border: 0.06666em solid #fff;
  background-color: #2b333f;
  background-color: rgba(43, 51, 63, 0.7);
  border-radius: 0.3em;
  transition: all 0.4s;
}
@media only screen and (min-width: 1920px) {
  .video-react-big-play-button-left {
    margin: 7em 11.5em;
  }
}
.green_container {
  display: flex;
  flex-direction: column;
  width: 402px;
  height: 413px;
  background-color: #e9f7f1;
  border-radius: 20px;
  padding: 2em 3em;
}
.transparent_container {
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  padding: 2em 3em;
}
.Ux__legend {
  width: 13em;
  font-size: 1.3em;
  margin: auto;
  color: #002c3f;
}
.col__ux {
  display: flex;
  flex-direction: column;
  height: -webkit-fill-available;
}
.card__container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 2em;
  padding: 3em 2em;
  width: 500px;
  height: 375.97px;
  box-shadow: 0 5px 20px rgba(0, 44, 63, 0.2) !important ;
  background: #ffffff;
  border-radius: 20px;
}
.card__container3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2em;
  padding: 3em 2em;
  width: 550px;
  height: 500px;
  box-shadow: 0 5px 20px rgba(0, 44, 63, 0.2) !important ;
  background: #ffffff;
  border-radius: 20px;
}
.card__container2 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 2em;
  padding: 1em;
  width: 400px;
  height: 200px;
  box-shadow: 0 5px 20px rgba(0, 44, 63, 0.2) !important ;
  background: #ffffff;
  border-radius: 20px;
}
.more {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 33px;
  /* identical to box height */

  display: flex;
  align-items: center;
  margin-top: auto;
  color: #00a359;
}
.why {
  text-align: start;
  font-size: 1.3em;
  color: #748c95;
}
.ref {
  text-align: start;
  font-size: 1.3em;
  color: #002c3f;
}

.name__font {
  font-size: 1.1em;
  font-weight: 500;
  margin-top: 1.5em;
  text-align: start;
}
.why {
  text-align: start;
  font-size: 1.3em;
  color: #002c3f;
}
.img__legend {
  font-size: 1.1em;
  font-weight: 600;
  color: #002c3f;
}
.reason {
  font-size: 1.1em;
  font-weight: 600;
  color: #002c3f;
  text-align: start;
}
.description {
  margin-top: 2em;
  width: fill-content;
  color: #748c95;
}
.description2 {
  text-align: start;
  color: #748c95;
  font-size: 1rem;
  width: 12em;
}
.description3 {
  color: #748c95;
  font-size: 0.9rem;
}
.description4 {
  color: #748c95;
  font-size: 0.8rem;
}
.reasons {
  width: 12em;
  display: flex;
}
.counters3 {
  /* counter-reset: counter-three; */
  display: flex;
  margin-top: 3em;
}
.reasons::before {
  /* counter-increment: counter-three;
    font-size: 7em;
    color: #00a359 ; 
    content: url("/src/assets/Insight_data_tableau_récap_39.svg"); */
  display: flex;
  flex-direction: column;
  position: relative;
  left: -50px;
}
.ref::before {
  content: open-quote;
}
.ref::after {
  content: close-quote;
}
.display__flex {
  display: flex;
  flex-direction: column;
}
.mobile_container {
  text-align: center;
  margin: auto;
  height: 31em;
  width: 15em;
  border-radius: 2rem;
  border: 1.5px solid #00a359;
  padding: 2rem 0rem;
  background-color: #f4f7f7;
}
.mobile2_container {
  text-align: center;
  margin: auto;
  height: 31em;
  width: 15em;
  border-radius: 2rem;
  border: 1.5px solid #00a359;
  padding: 2rem 0rem;
}
.mobile_header {
  text-align: center;
  font-size: 1.3em;
  color: #002c3f;
}
.mobile_body {
  text-align: center;
  font-size: 1.3em;
  color: #002c3f;
  margin: 0 !important;
}
.mobile_body2 {
  text-align: center;
  font-size: 1.3em;
  color: #f4f7f7;
  margin: 0 !important;
}
.mobile_body3 {
  text-align: center;
  font-size: 0.9rem;
  color: #f4f7f7;
}
.line2 {
  margin: 0 !important;
  padding: 0 !important;
  width: 100%;
  background-color: #00a359;
}
.btn__mobile {
  font-size: 1em !important;
  margin-bottom: 0.5em;
  margin-top: 2em;
  background: #00a359 !important;
  color: white !important;
  border: none !important;
  padding: 10px 20px !important;
  border-radius: 2rem !important;
  font-family: sans-serif;
}
.video-react {
  background-color: initial !important;
}
.video-react.video-react-fluid,
.video-react.video-react-16-9,
.video-react.video-react-4-3 {
  margin-top: 1em;
}
.video-react .video-react-big-play-button {
  display: none !important;
  top: 100px !important;
  left: 175px !important;
}
.btn__mobile2 {
  font-size: 1em !important;
  margin-bottom: 0.5em;
  margin-top: 3.5em;
  background: #00a359 !important;
  color: white !important;
  border: none !important;
  padding: 10px 20px !important;
  border-radius: 2rem !important;
  font-family: sans-serif;
}
.mobile2_container:hover {
  box-shadow: 0 5px 20px rgba(0, 44, 63, 0.2) !important ;
}
.mobile_container:hover {
  box-shadow: 0 5px 20px rgba(0, 44, 63, 0.2) !important ;
}
.img_video {
  cursor: pointer;
  width: 103%;
  margin-left: -1.75em;
  height: 27em;
}

.navBar__horizontal {
    width: 100%;
    min-height: 6em !important;
    background-color: #ffffff;
    position: fixed !important;
    display: flex;
   flex-grow: 1;
    flex-direction: row;
    padding: 0 1rem !important;
    z-index: 11;
    
}
.btn__sign{
    font-family: sans-serif !important;
    font-style: normal;
    font-weight: 500 !important;
    font-size: 16px !important;
    background-color: #00a359 !important;
    border-color: #00a359 !important;
    border-radius: 8px !important;
    margin-right: 1rem;
    width: 238px;
height: 43px;
margin-top: .9em;
}
.btn__sign2{
    font-family: sans-serif !important;
    font-style: normal;
    font-weight: 500 !important;
    font-size: 16px !important;
    background-color: rgba(68, 61, 246, 0.1) !important;
    border-color: rgba(68, 61, 246, 0.1) !important;
    border-radius: 8px !important;
    color: #969696 !important;
    width: 238px;
height: 43px;
margin-top: .9em;
}
.item__header {
    font-size: 1em;
    font-weight: 400;
    display: inline-block;
    font-family:   sans-serif;
    
}
.nav__bar{
    display: flex;
    flex-grow: 1;
}
.nav__bar div:nth-of-type(1) {
    margin-left: auto;
}
.nav__bar div:nth-of-type(6) {
    width: 7em;
}
.nav__bar div:nth-of-type(5) {
    width: 7em;
}


.item__nav__bar {
    padding-top: 1.5em;
    width: 8.4em;
    text-align: center; 
    color: #002c3f;
}

.item__nav__bar__selected {
    padding-top: 1.5em;
    width: 8.4em;
    color: #00a359 !important;
    text-align: center;
    
}
.item__nav__bar_lang{
    padding-top: 1.25em;
    width: 8.4em;
    text-align: center;
}
.item__nav__bar:hover {
    color: #00a359;
}
.btn__signup{
    background-color: #00a359 !important;
    border: none !important;
    border-radius: 30px !important;
    height: 55px;
    font-size: 13px;
    margin-top: .75em;
    width: 11em;
    font-family:   sans-serif;
   
}
.btn__sign:hover {
    /* background-color: #03c56e  !important; */
    box-shadow:0 5px 20px rgba(0,44,63,0.2) !important ;
    color: white;
  }
.btn__sign2:hover {
    /* background-color: #03c56e  !important; */
    box-shadow:0 5px 20px rgba(0,44,63,0.2) !important ;
    color: #969696;
  }
.logo{
      height: 80px;
      width: 120px;
  }
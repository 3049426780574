.ctn__login{
    /* background-color: rgb(243, 251, 247); */
    width: 40%;
    max-height: 60vh;
   
}
.footer__login{
    margin-top: 1.5em ;
    display: flex;
    flex-direction: column;
    
}
.form__login{
    align-items: center;
    margin: auto;
}
#password__login,
#email__login,
textarea {
    width: 350px; 
    font-size: 1em;
    height: 50px;
    border-radius: 19px;
    border: solid 1px #d3d3d3 !important;
    color: #002C3F;
  }
.login__button{
    width: 170px;
    margin-bottom: 1em;
    margin-top: 1em;
    background: #00a359 !important;
    color: white !important;
    border: none !important;
    padding: 10px 20px !important;
    border-radius: 6px !important;
    font-size: 1.1em !important;
    font-family:   sans-serif; 
}

.line {
    border: 1px solid rgb(212, 211, 211) !important;
    min-width: 100%;
  }
.line3 {
    border: 1px solid rgb(212, 211, 211) !important;
    min-width: 99%;
  }
#login__input{
    height: 48px;
    margin-bottom: 1.2em;
    
}
#login__input2{
    height: 48px;
    margin-bottom: 1.2em;
    
}
#text__footer{
 font-size: 1.1em;
 font-weight: 400;
 font-family:   sans-serif;   
}
.form__login{
    margin:1.5em auto;
    display: flex;
    flex-direction: column;
}
#header__login{
    font-size: 1.25em;
    font-weight: bold;
    font-family:   sans-serif; 
}

.forget__password{
    color: #00a359 ;
    font-size: 1.1em !important;
    font-weight: 400;
    font-family:   sans-serif; 
    text-decoration: underline;
}
.login__button:hover {
    /* background-color: #03c56e  !important; */
    box-shadow:0 5px 20px rgba(0,44,63,0.2) !important ;
    color: white;
  }
#feed{

    margin-top: -1.5em;
}
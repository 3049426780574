.no-padding {
  padding: 0 !important;
}
.no-margin {
  margin: 0 !important;
}
.main {
  background-color: $white;
  border-radius: 15px;
}
.bg-grey {
  background-color: #f1f1f1;
  justify-content: space-around;
}
.bg-red {
  background-color: red;
}
.font-small-1 {
  font-size: 10px;
}
.font-small-2 {
  font-size: 12px;
}
.font-small-3 {
  font-size: 14px;
}
.font-medium-1 {
  font-size: 15px;
}
.font-medium-2 {
  font-size: 18px;
}
.font-medium-3 {
  font-size: 20px;
}
.font-weight-600 {
  font-weight: 600;
}
.font-weight-700 {
  font-weight: 700;
}
.comment-label {
  font-weight: 700;
}
.font-weight-500 {
  font-weight: 500;
}
.profile-avatar {
  width: 170px;
  height: 190px;
  border-radius: 10px;
}
.column-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.column-left-comment {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-left: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
  width: 350px;
}
.column-left-comment-open {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 15px;
  width: 350px;
  //height: 300px;
}
.round-img {
  height: 150px;
  width: 150px;
  border-radius: 50%;
}
.card-padding-top {
  padding-top: 20px;
}
.text-padding-top {
  padding-top: 5px;
}
.padding-btn {
  padding-bottom: 15px;
}
.top-bandeau {
}
.flex-column {
  flex-direction: column;
}
// const color1 = "#301934";
//const color2 = "#B408A4";
.tester-header {
  background: linear-gradient(to right, green 0%, #32cd32 100%);
  border-radius: 5px;
  margin: 30px;
  margin: 20px;
  display: flex;
  height: 90px;
  justify-content: space-between;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}
.tester-header-reduced {
  background: linear-gradient(to right, green 0%, #32cd32 100%);
  border-radius: 5px;
  margin: 30px;
  margin: 20px;
  display: flex;
  height: 70px;
  justify-content: space-between;
  align-items: center;
}
.padding-modal {
  padding-left: 20px;
  padding-right: 20px;
}
.height-X {
  height: 55px;
}
.bank-card {
  background-color: white;
  border-color: black;
  border-width: 2px;
  border-radius: 0;
}
.bank-card-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: 10px;
}
.bank-card-body {
  background-color: #e0ecfe;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  height: 400px;
  width: 100%;
  padding: 20px;
}
.important-text {
  color: red;
  font-size: 20px;
  font-weight: 500;
}
.card-texts {
  font-weight: 600;
  font-size: 14px;
  text-align: left;
}
.iban {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-top: 10px;
}
.test-sound {
  display: flex;
  flex-direction: row;
  margin: 10px;
  width: 300px;
  align-items: center;
  justify-content: space-between;
}
.test-mic {
  display: flex;
  flex-direction: row;
  margin: 10px;
  width: 300px;
  align-items: center;
  justify-content: space-between;
}
.headset-image {
  height: 40px;
  width: 40px;
}
.iframe {
  height: 1300px;
  width: 100%;
  z-index: 9999999;
  display: flex;
  
}
.img-test {
  display: flex;
  justify-content: left;
  align-items: flex-start;
  padding-left: 20px;
}
.link-style {
  font-weight: 500;
  color: blue;
}
.questions {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
}
.center-modal {
  height: 500px;
  width: 500px;
}
.quiz {
  display: flex;
  width: 400px;
  background-color: white;
  position: absolute;
  //top: 300px;
  left: 20px;
  top: 40px;
  border-radius: 5px;
  border-color: gray;
}
.question-container {
  display: flex;
  align-items: flex-start;
  width: 350px;
  margin-bottom: 15px;
}
.question-typo {
  font-weight: 700;
  font-size: 14px;
  text-align: left;
}
.questions-typo {
  font-weight: 700;
  font-size: 15px;
}
.radio {
  display: flex;
  align-items: center;
}
.raw-container {
  display: flex;
  flex-direction: column;
  height: "100%";
  width: "100%";
  padding-left: 40px;
  padding-right: 40px;
  background-color: #f0f0f0;
}
.s-top-component-container {
  height: 100px;
  width: "100%";
  border-radius: 10px;
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding: 15px;
  margin-top: 20px;
  background-color: white;
}
.first-resume {
  width: "100%";
  border-radius: 10px;
  display: flex;
  flex: 1;
  flex-direction: column;
  // align-items: flex-start;
  justify-content: space-between;
  padding: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-bottom: 20px;
  background-color: white;
}
.top-component-container {
  height: 70px;
  width: "100%";
  border-radius: 10px;
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  margin-top: 20px;
  background-color: white;
}
.col-top-component-container {
  height: 115px;
  width: "100%";
  border-radius: 10px;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  margin-top: 20px;
  background-color: white;
}
.sub-top-component-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.sub-sub-top-component-container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
.sub-sub-top-component-container-sc {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  background-color: green;
  width: 120px;
  height: 30px;
  border-radius: 5px;
}
.question-resume-container {
  display: flex;
  align-items: flex-start;
}
.chart-container {
  display: flex;
  align-items: center;
  width: 600px;
  // height: 1500px;
  padding-top: 10px;
  flex-direction: column;
}
.pie-chart-container {
  display: flex;
  align-items: center;
  width: 300px;
  // height: 1500px;
  padding-top: 10px;
  flex-direction: column;
}
.top-component-green-style {
  font-weight: 900;
  font-size: 20px;
  font-weight: 500;
  color: #00A359;
}
.top-component-black-style {
  font-weight: 500;
  font-size: 18px;
  color: black;
  &:hover {
    font-weight: 500;
  }
}
.top-component-white-style {
  font-weight: 900;
  font-size: 18px;
  color: white;
  &:hover {
    font-weight: bold;
  }
}

.top-component-fine-black-style {
  font-weight: 300;
  font-size: 18px;
  color: black;
}
.excel-img {
  height: 30px;
  width: 30px;
}
.welcome-text {
  font-weight: 800;
  font-size: 40px;
  color: green;
}
.question-typo-number {
  font-weight: 600;
  font-size: 12px;
  text-align: left;
  color: green;
}
.valuesScaleContainer {
  display: flex;
  flex-direction: row-reverse;
  cursor: pointer;
}
.border-bottoms {
  border-bottom: solid green;
  width: 170px;
}
.border-bottoms-without {
  width: 170px;
}
.cursor-pointer {
  cursor: pointer;
}
.pick-tester-text {
  color: black;
  font-weight: 700;
  font-size: 12px;
}
.open-by-testers-questions-container {
  display: flex;
  align-items: flex-start;
  width: 100%;
  padding-top: 20px;
}
.border-bottoms-question {
  border-bottom: solid lightgray thin;
}
.scale-tester-array {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
.first-resume-raw-tester {
  width: "100%";
  height: 1500px;
  border-radius: 10px;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px;
  margin: 50px;
  background-color: white;
}
.sound-waves {
  height: 60px;
  width: 220px;
  display: flex;
  margin-right: 100px;
}
.cam-container {
  display: flex;
  margin-right: 80px;
  padding-top: 10px;
}
.bottom-modal-display {
  margin-top: 1300px;
}
.pop-up-contract {
  display: flex;
  flex-direction: column;
  height: 300px;
  overflow: scroll;
  border-width: 1em;
  border-radius: 5px;
  margin: 10px;
  padding: 10px;
  border: 1px solid #e0e0e0;
  white-space: pre-wrap;
}
.profile-box {
  height: 120px;
  width: 140px;
  border-radius: 10px;
  background-color: white;
  display: flex;
  margin: 20px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.profile-card {
  font-weight: 700;
  font-size: 12px;
  color: green;
}
.hh {
  height:250px
}

.select-id-handler{
  &:hover {
    color:green;
    cursor: pointer;
  }
}

.column-left-1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width:'50%'
}
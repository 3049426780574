.scenario__info{
    display: flex;
    flex-direction: column;
    width: 80%;
    min-height: 20vh; 
    margin: 3em auto 0 auto;
}
.col__style{
    margin: 1em .5em;
    background-color: #f4f7f7;
    /* border:solid 1px #00a359; */
    color: #002C3F;
    border-radius: 5px;
    min-width: 150px;
    min-height: 7em;
    
}
.db__title{
    font-family:   sans-serif;
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 29px;
text-align: start !important;
color: #00A359;
display: flex;
margin: .5em 0 1em .5em;

}
.shortcut{
    font-family:   sans-serif;
    font-style: normal;
font-weight: 500 !important;
font-size: 12px !important;
    margin-bottom: 2em;
    margin-right: 1em;
    margin-left: .5em;
    min-width: 146px;
    color: #002C3F !important;
min-height: 38px;
border: none !important;
background-color: #F3FBF7 !important;
border-radius: 5px !important;
}
.shortcut:hover{
    box-shadow:0 5px 20px rgba(0,44,63,0.2) !important ;
    color: #00a359 !important;
}
.scenario__ctn{
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    background-color: white;
    /* border:solid 1px #00a359; */
    margin-bottom: 2em;
    padding: 1em;
}
#text__area{
    font-family: sans-serif;
    font-size: 13px;
    font-style: italic;
}
.scenario2__ctn{
    display: flex;
    flex-direction: row;
   align-items: flex-start;
}
.paneltester__ctn{
    display: flex;
    flex-direction: column;
    width: 100%;
    
    background-color: white;
    /* border:solid 1px #00a359; */
    /* margin-bottom: 2em; */
    padding: 1em 6em;
}
.scenario__contain{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1em 2em;
}
.display__flex__row{
    display: flex;
}
.display__flex__col__center{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.display__flex__col{
    display: flex;
    flex-direction: column;
  
}
.error{
    text-align: start;
}
.next{
    font-family:   sans-serif;
    font-style: normal;
    font-weight: 500 !important;
    font-size: 14px !important;
    border-radius: 8px !important;
    background-color: rgba(68, 61, 246, 0.1) !important;
    border-color: rgba(68, 61, 246, 0.1) !important;
    color: #00a359 !important;
    max-width: 97px;
    min-width: 97px;
    margin: 2em;
    line-height: 1 !important;
    height: 30px;
   
    
}
.go{
    font-family:   sans-serif;
    font-style: normal;
    font-weight: 500 !important;
    font-size: 14px !important;
    border-radius: 8px !important;
    background-color: rgba(68, 61, 246, 0.1) !important;
    border-color: rgba(68, 61, 246, 0.1) !important;
    color: #00a359 !important;
    max-width: 100px;
    min-width: 100px;

    line-height: 1 !important;
    height: 30px;
   
    
}
.next__error{
    font-family:   sans-serif;
    font-style: normal;
    font-weight: 500 !important;
    font-size: 14px !important;
    border-radius: 8px !important;
    background-color: rgba(68, 61, 246, 0.1) !important;
    border-color: rgba(68, 61, 246, 0.1) !important;
    color: #00a359 !important;
    min-width: 97px;
    margin: .75em 2em;
    line-height: 1 !important;
    height: 30px;
}
.next:hover{
    box-shadow:0 5px 20px rgba(0,44,63,0.2) !important ;
    color: #00a359 !important;
}
.go:hover{
    box-shadow:0 5px 20px rgba(0,44,63,0.2) !important ;
    color: #00a359 !important;
}
.finish{
    font-family:   sans-serif;
    font-style: normal;
    font-weight: 500 !important;
    font-size: 14px !important;
    border-radius: 8px !important;
    /* background-color: rgba(68, 61, 246, 0.1) !important; */
    border-color: rgba(68, 61, 246, 0.1) !important;
    Background-color: #00a359 !important;
    min-width: 97px;
    /* max-width: 97px; */
    margin: 2em;
    line-height: 1 !important;
    height: 30px;
}
.finish:hover{
    box-shadow:0 5px 20px rgba(0,44,63,0.2) !important ;
    /* color: #00a359 !important; */
}
.create__scenario__ctn{
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: white;
    margin-bottom: 2em;
    padding: 10em 0em;
}
.panel__ctn{
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: white;
    border:solid 1px #00a359;
    margin-bottom: 2em;
    padding: 1em;
}
.row__create_sc{
    height: 20vh;
    align-items: center;
    justify-content: center;
}
.row__create_pt{
    display: flex;
    height: 30vh;
    align-items: center;
    justify-content: space-between;
    margin: 0em auto;
}
.row__title__sc{
    height: 10vh;
    justify-content: center;
    align-items: center;
}
.col__create__sc{
    font-size: 1em;
    font-weight: 500;
}
.line__sc {
    border: 1px solid #00a359 !important;
    min-width: 80%;
  }
  .title__style{
      font-size: 1.25em;
      font-weight: 500;
      margin: 1em auto;
      color: #000000 ;
  }
  .scenario__name{
    font-family: sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 35px;
    /* identical to box height */
    
    
    color: #000000;
  }
  .step_container{
      margin-top: 2em;
      background-color: #f4f7f7;
      border-radius: 20px;
      min-height: 329px;
      display: flex;
      color: black;
      text-align: left;
      padding: 2em ;
  }
  .desc__style{
      margin-top: 1em;
    font-family: sans-serif;
    font-style: italic;
    font-weight: 300;
    font-size: 13px;
    line-height: 35px;
    /* identical to box height */
    
    
    color: #000000;
  }
  #panel{
    border: 1px solid rgba(0, 0, 0, 0.17);
    box-sizing: border-box;
    border-radius: 10px;
    
  }
  #number{
    border: 1px solid rgba(0, 0, 0, 0.17);
    box-sizing: border-box;
    border-radius: 10px;
    width: 10%;
  }
  #number2{
    border: 1px solid rgba(0, 0, 0, 0.17);
    box-sizing: border-box;
    border-radius: 10px;
    width: 18%;
  }
  #support{
    border: 1px solid rgba(0, 0, 0, 0.17);
    box-sizing: border-box;
    border-radius: 10px;
    width: 40%;
  }
  #support2{
    border: 1px solid rgba(0, 0, 0, 0.17);
    box-sizing: border-box;
    border-radius: 10px;
    width: 60%;
  }
  .title__Panel{
      font-size: 24px;
      font-weight: 600;
      text-align: start;
      margin-bottom: 1.5em;
      color: #000000 ;
      font-family:   sans-serif;
  }
  .type_panel{
    font-family:   sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    color: #544747;
  }
  .type_panel2{
    font-family:   sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    color: #544747;
    width: 75px;
  }
  .container__img{
      width: 100px ;
      height: 100px ;
     display: flex;
  }
  .container__img2{
      width: 12em ;
      height: 11em ;
     display: flex;
  }
  .container__img:hover{
      
    box-shadow:0 5px 20px rgba(0,44,63,0.2) !important ;
    cursor: pointer;
  }
  .container__img2:hover{
    border-radius: 20px;
    background-color: #f4f7f7;
    cursor: pointer;
  }
  .image_r {
    width: 100px !important;
    height: 100px ;
       border: 1px solid #00a359 !important;
  }
  .steps__container{
      border-radius: 20px;
      background-color: #f4f7f7 ;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 1em;
      min-width: 17em;
  }
  .title__steps{
    font-family:   sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 19px;
    margin-bottom: 2em;
    color: #002C3F;
  }
  .tick__scenario{
    width: 16px;
    margin: 0 0 5px 0.25em;
  }
  .title__steps2{
    font-family:   sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 2em;
    color: #002C3F;
  }
  .step__init{
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    font-family:   sans-serif;
   
  }
  .step__creation{
display: flex;
text-align: start;
margin-top: 16em ;
  }
  .step__init__body{
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    font-family:   sans-serif;
  }
  .step{
    font-family:   sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    margin-bottom: .5em;
    color: #002C3F;
    cursor: pointer;
  }
  .step__selected{
    font-family:   sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    margin-bottom: .5em;
    color: #002C3F;
    cursor: pointer;
  }
  .image_r2 {
      display: flex;
    width: 12em !important;
    height: 12em ;
       /* border: 1px solid #00a359 !important; */
       background-color: #f4f7f7;
       border-radius: 20px;
       flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .image_r2_selected{
    display: flex;
    width: 12em ;
    height: 12em ;
    border-radius: 20px;
    /* border: 1px solid #002C3F !important; */
    border: 1px solid #00a359 !important;
    box-shadow:0 5px 20px rgba(0,44,63,0.2) !important ;
   background-color: #f4f7f7;
   flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .image_r_selected{
    width: 100px ;
    height: 100px ;
    border: 1px solid #002C3F !important;
   background-color: #f4f7f7;
  }
  /* .image_r::after{
      content: '';
  } */
  .check{
      margin-left: 3em;
      
  }
  .check2{
      display: flex;
      margin-left: 3em;
      align-items: center;
      justify-content: center;
  }
  .row__imgs{
      display: flex;
  }
  .toolBox__ctn{
  justify-content: center;
  align-items: center;
  min-height: 20vh;
  display:flex;
  flex-direction: column;
  width:25vw;
  border:1px solid #00a359;
  padding: 0 1rem;
  border-radius: 24px;
  margin-top: 5.5em;
  position: fixed;
  }
  .filterBox__ctn{
    justify-content: center;
    align-items: center;
    min-height: 25vh;
    display:flex;
    flex-direction: column;
    width:27vw;
    border:1px solid #00a359;
    padding: 0 1rem;
    border-radius: 24px;
    position: fixed;
  }
  .filter__form{
    /* justify-content: center;
    align-items: center; */
    min-height: 45vh;
    display:flex;
    flex-direction: column;
    width:57vw;
    border:1px solid #00a359;
    padding: 2em;
    border-radius: 24px;
    
  }
  .step__ctn{
    justify-content: center;
    align-items: center;
    min-height: 20vh;
    display: flex;
    flex-direction: row;
    max-width: 38vw;
    border: 1px solid #00a359;
    padding: 1rem;
    min-width: 38vw;
  }
  .toolBox__button{
    font-size: .75em !important;
    margin-bottom: 1em;
    margin-top: 1em;
    background: #00a359 !important;
    color: white !important;
    border: none !important;
    padding: 10px 20px !important;
    border-radius: 6px !important;
    font-family:   sans-serif; 
  }
  .toolBox__button:hover {
    
    box-shadow:0 5px 20px rgba(0, 35, 49, 0.2) !important ;
    color: white;
  }
.btn__delete{
    width: 2em;
    cursor: pointer;
 
}
#input__visu{
    width: 70%;
}
.col__back{
    display: flex;
    justify-content: space-around;
    align-items: center;
}
#url__style{
  font-family: sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
    border: 1px solid rgba(0, 0, 0, 0.17);
box-sizing: border-box;
border-radius: 10px;
}
#resp__style{
  font-family: sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  border: none;
  border-bottom: dashed rgba(0, 0, 0, 0.17);
box-sizing: border-box;

}

.label__step{
  font-family: sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  margin-bottom: 1.9em;
}
.icons__style{
  display: flex;
  margin-top: 13em;
  justify-content: center;
}
.btn__edit{
    width: 2em;
    cursor: pointer;
}
.btn__scale{ 
    width: 4em;
}
.iframe__form{
    width: 100%;
    min-height: 100vh;
}
.btn-outline-success {
    
    color: #00a359 !important;
    border-color: #00a359 !important;
}
.btn-outline-success:hover {
    
    color: #fff !important;
    background-color: #00a359 !important;
    border-color: #00a359 !important;
}

#scale__range{
  border: none;
  box-sizing: border-box;
  border-radius: 10px;
  max-width: 15%;
  font-family: sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  /* background-color: #f4f7f7; */
}

.step__title{
  display: flex;
  text-align: start;
  margin: 1em 0 -1em 2em;
  font-family: sans-serif;
  font-style: normal;
  font-weight: 600;
}
.add__option{
  text-decoration: underline;
  font-family: sans-serif;
font-style: normal;
font-weight: 300;
font-size: 16px;
cursor: pointer;
}
.radio__label{
  margin-right: 1em;
  font-family: sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
}
.validate__icon{
    width: 30px;
}
.validate__icon:hover{
cursor: pointer;
}
.row__form {
    flex-wrap: unset !important;
    margin-bottom: 1em;
}
.row__visu{
    flex-wrap: unset !important;
    margin-bottom: 1em;
    margin-left: 0 !important;
}
.label__form{
    width: 10em;
    text-align: initial;
}
.label__form3{
    width: 12em;
    text-align: initial;
}
.label__form2{
    width: 6.75em;
    font-size: 16px;
    text-align: initial;
    margin: 0 1.75em;
    font-style: italic;
}
.row_panel{
    margin-left: 0 !important;
    margin-right: 0 !important;
}
input[type="file"] {
    display: none;
}
.custom-file-upload {
    border: 1px solid #00a359;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    height: 2.35em;
}

.file-preview {
  margin: 0 10px;
}
.btn__1{
    font-family: sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    background-color: #00a359 !important;
    border-color: #00a359 !important;
    margin-right: 1rem;
    width: 238px;
  }
  .btn__2{
    font-family: sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    background-color: rgba(68, 61, 246, 0.1) !important;
    border-color: rgba(68, 61, 246, 0.1) !important;
    width: 238px;
    color: #00a359 !important;
  }
  .btn__2:hover {
    box-shadow:0 5px 20px rgba(0,44,63,0.2) !important ;
    color: #00a359 !important;
  }
  .btn__1:hover {
    box-shadow:0 5px 20px rgba(0,44,63,0.2) !important ;
    color: white;
  }
  .cam_style{
    position: fixed;
    left: 87vw;
    top: -23px;
    width: 12em;
    height: 12em;
  }
#label__style__signup{
    display: flex;
    color: #002C3F;
    font-size: 1.1em;
    font-weight: 400;
}
.ctn__signup{
    width: 100%;
    background-color: white;
    margin-top: 6em;
}
.signup__form{
    margin-left:2em;
    width: 30%;
}
#header__signup{
    font-size: 1.25em;
    font-weight: bold;
    color: #00a359;  
}
.signup__button{
    font-size: 1.1em !important;
    margin-bottom: 1em;
    margin-top: 1em;
    background: #00a359 !important;
    color: white !important;
    border: none !important;
    padding: 10px 20px !important;
    border-radius: 6px !important;
    font-family:   sans-serif; 
}
.signup__button:hover {
    /* background-color: #03c56e  !important; */
    box-shadow:0 5px 20px rgba(0, 35, 49, 0.2) !important ;
    color: white;
  }
  .footer__signup{
      margin-bottom: 2em;
      
  }
  .invalid-feedback {
    display: unset !important;
   margin-top: -1.5em;
}
#password,
#email,
#phone,
#sector,
#useCase,
#nbEmployees,
#firstName,
#lastName,
#age,
#genre,
#situation__familiale,
#pays,
#csp,
#niveau,
#reseau,
#os,
#profession,
#company,
textarea {
  /* width: 350px; */
  font-size: 1em;
  height: 50px;
  border-radius: 19px;
  border: solid 1px #d3d3d3 !important;
  color: #002C3F;
}
*:focus {
  outline: none;
}
.css-1s2u09g-control{
    font-size: 1em;
    height: 50px;
    border-radius: 19px!important;
    border: solid 1px #d3d3d3 !important;
    color: #002C3F;
}
.css-1s2u09g-control:hover{
    font-size: 1em;
    height: 50px;
    border-radius: 19px !important;
    border: solid 1px #d3d3d3 !important;
    color: #002C3F;
}
.css-1s2u09g-control:focus{
    font-size: 1em;
    height: 50px;
    border-radius: 19px !important;
    border: solid 1px #d3d3d3 !important;
    color: #002C3F;
}
.css-1pahdxg-control{
    font-size: 1em;
    height: 50px;
    border-radius: 19px !important;
    border: solid 1px #d3d3d3 !important;
    color: #002C3F;
}

.square1{
    font-weight: bold;
    color: gold;
}
.square2{
    font-weight: bold;
    color: red;
}
.square3{
    font-weight: bold;
    color: #002C3F;
}
.favoris{
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}
#btn__add__manager{
    position: fixed;
    /* background-color: rgb(0, 163, 89);
    color: white; */
    z-index: 10;
    top: 45em;
    left: 103em;
    border: none;
    outline: none;
}
#btn__add__tester{
    position: fixed;
    /* background-color: rgb(0, 163, 89);
    color: white; */
    z-index: 10;
    top: 45em;
    left: 104.5em;
    border: none;
    outline: none;
}
#btn__add__manager:hover{

    box-shadow:0 5px 20px rgba(0,44,63,0.2) !important ;
    
    
}
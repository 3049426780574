.header{
    width: 100%;
    border-radius: 15px;
    min-height: 6em;
    padding: 1em 2em;
    background-color: #f4f7f7;
    margin-top: 1em;
    align-items: baseline;
}
.header_label{
    font-family: sans-serif;
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 23px;
text-align: center;
color: #000000;
}
.header_span{
    margin-top: .5em;
    font-family: sans-serif;
font-style: normal;
font-weight: 300;
font-size: 20px;
line-height: 23px;
text-align: center;
color: #000000;
}
.body1{
    background-color: #f4f7f7;
    width: 100%;
    margin: 1em auto;
    border-radius: 15px;
    min-height: 29em;
    /* margin-top: 1em; */
    /* display: flex; */
    flex-direction: column;
    
}
.body2{
    background-color: #f4f7f7;
    width: 100%;
    margin: 1em auto;
    border-radius: 15px;
    min-height: 35em;
    /* margin-top: 1em; */
    /* display: flex; */
    flex-direction: column;
    padding-bottom: 4em;
    
}
.video_label{
    font-family: sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    
    color: #000000;
}
.row_resp{
    margin-top: 2em;
    text-align: start;
}
.video_span{
    font-family: sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    
    color: #000000;
}
.analyze_form{
    display: flex;
    flex-direction: column;
    width: 80%;
    min-height: 20vh;
    margin: auto;
    padding-bottom: 2em;
}
.row_1{
    /* display: flex; */
    justify-content: flex-end;
    min-height: 2em;
    padding: .25em .5em
}
.img_style{
    margin-right: 1em ;
    cursor: pointer;
}
.img_style2{
    margin-right: 1em ;
    width: 14px;
height: 30px;

}
.chart_container{
    padding: 1em 0em 1em 7em;
    min-height: 27em
}
.journey_map{
    font-family: sans-serif;
font-style: normal;
font-weight: 500;
font-size: 25px;
color: #000000;
margin-left: auto;
margin-right: 2.5em;
line-height: 1;
}
.duration{
    font-family: sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    /* identical to box height */
    line-height: 30px;
    
    color: #000000;
margin-left: auto;
}
.filter_text{
    font-family: sans-serif;
font-style: normal;
font-weight: 300;
font-size: 20px;
line-height: -1px;
margin-right: 2.5em;
color: #002C3F;
}
.btn_filter{
    border-radius: 20px !important;
    min-width: 94px;
    height: 31.2px;
border: none !important;
margin-right: 2em;
font-family: sans-serif !important;
font-style: normal;
font-weight: 500 !important;
font-size: 13px !important;
line-height: 15px;
color: #c2c2c2 !important;
/* identical to box height */
background: rgba(204, 204, 204, 0.11) !important;
text-align: center !important;
}
.btn_filter_selected{
    border-radius: 20px !important;
    min-width: 94px;
border: none !important;
height: 31.2px;
margin-right: 2em;
font-family: sans-serif !important;
font-style: normal;
font-weight: 500 !important;
font-size: 13px !important;
line-height: 15px;
color: #fff !important;
/* identical to box height */
background: #17A25C !important;
text-align: center !important;
}
.btn_filter_select{
    border-radius: 20px !important;
    width: 94px !important;
border: none !important;
height: 31.2px;
margin-right: 2em;
font-family: sans-serif !important;
font-style: normal;
font-weight: 500 !important;
font-size: 13px !important;
line-height: 15px;
color: #fff !important;
/* identical to box height */
background: #17A25C !important;
text-align: center !important;
}
.btn_filter_select_scena{
    border-radius: 20px !important;
    width: 150px !important;
border: none !important;
height: 31.2px;
margin-right: 2em;
font-family: sans-serif !important;
font-style: normal;
font-weight: 500 !important;
font-size: 13px !important;
line-height: 15px;
color: #fff !important;
/* identical to box height */
background: #17A25C !important;
text-align: center !important;
margin-right: 5em;
}
.filter2{
padding-left: 7.7em;
margin-bottom: 1em;
}
.filter{
margin-bottom: 1em;
}
.chat_style{
    margin-left: -2em !important;
    width: 95% !important;
}
.top_3_3{
width: 62px;
height: 26px;
background: rgba(237, 84, 84, 0.63);
border-radius: 20px;
}
.top_3_2{
width: 62px;
height: 26px;
background: rgba(231, 25, 25, 0.74);
border-radius: 20px;
margin-right: 1em;
}
.top_3_1{
width: 62px;
height: 26px;
background: #E71919;
border-radius: 20px;
margin-right: 1em;
}
.top_3{
width: 62px;
height: 26px;
background: rgba(0, 163, 89, 0.58);
border-radius: 20px;
margin-right: 1em;
}
.top_2{
width: 62px;
height: 26px;
background: rgba(23, 162, 92, 0.64);
border-radius: 20px;
margin-right: 1em;
}
.top_1{
width: 62px;
height: 26px;
background: #17A25C;
border-radius: 20px;
margin-right: 1em;
}
.label_top{
    font-family: sans-serif;
font-style: normal;
font-weight: 500;
font-size: 22px;
margin-right: 1em;

color: #008000;
}
.label_top2{
    font-family: sans-serif;
font-style: normal;
font-weight: 500;
font-size: 22px;
margin-right: 1em;

color: #FE0C0C;
}
.play{
    width: 5em;
    margin: 7em 0 0 0;
    cursor: pointer;
}
.video_container{
    width: 100%;
    height: 20em;
    background-color: white;
    box-shadow:0 5px 20px rgba(0,44,63,0.2) !important ;
}
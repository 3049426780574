.modal-title{
    margin: auto;
    color: #00a359; 
}
.modal-header .close{
    padding: 0 !important;
    margin: 0 !important;
}
.btn_modal_ctn{
    width: max-content;
    margin: auto;
}
.modal__form{
    width: 75%;
    margin: auto;
}
.btn__form__modal {
    background: #00a359 !important;
    color: white !important;
    border: none !important;
    padding: 10px 20px !important;
    border-radius: 6px !important;
    font-size: 1.1em !important;
    font-family:   sans-serif; 
}
.btn__form__modal:hover {
    /* background-color: #03c56e  !important; */
    box-shadow:0 5px 20px rgba(0, 35, 49, 0.2) !important ;
    color: white;
  }
  .redirect__signup{
    border-radius: 5px;
    background-color: #f3fbf7;
    padding: 1em;
  }
  .MuiToolbar-gutters {
    padding-left: 16px !important;
    padding-right: 16px !important;
}
.lang__ctn{
    /* display: -webkit-box; */
    /* flex-direction: row; */
    margin-top: 1.2em;
    margin-left: .6em;
    width: 67px;
    display: unset;

}
.lang{
    width: 2em;
    cursor: pointer;
}

.nav-tabs .nav-link.active{
    color: #00a359 !important;
    border: solid 1px #002C3F !important;
}
.item__header__icon{
    color: #002C3F !important;
}
.item__header2{
    color: #00a359 !important;
    font-family:   sans-serif;
}
.dropdown-toggle::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: none !important;
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
}
.nav-tabs .dropdown-menu {
    width: 14.05em;
}
.drop__down{
    padding: 0 .5rem;
}